<script>
export default {
  name: 'search-bar',

  props: {
    showLatest: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      query: this.value,
    };
  },

  mounted() {
    const initQuery = this.$router.currentRoute.query.q;

    if (initQuery) {
      this.query = initQuery;
    }
  },

  methods: {
    search() {

      if (this.query.length === 0) {
        return;
      }
      console.log("2  ", this.query);
      this.$router.push({ name: 'library-search', query: { q: this.query } }).catch(() => {});
    },

    updateLocalStore(query) {
      const current = JSON.parse(localStorage.getItem('searches')) || [];
      current.unshift(query);
      current.slice(2);

      localStorage.setItem('searches', JSON.stringify(current));
    },
  },
};
</script>

<template>
  <div class="p-2 relative mx-auto text-gray-600 w-full">
    <input
      class="shadow h-16 text-3xl appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      type="search"
      name="search"
      v-model="query"
      @keyup.13="search()"
      :placeholder="$t('search')"
    />
    <button type="submit" class="h-full absolute right-0 top-0 flex p-8 items-center">
      <i class="fas fa-search text-2xl" @click="search()"></i>
    </button>
  </div>
</template>
