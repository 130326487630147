<script>

export default {
  name: 'news-card',

  props: {
    title: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    published: {
      type: String,
      required: false
    }
  },

  methods: {
    inspect(id) {
      this.$router.push({ name: 'illustration', params: { id } }).catch(console.log);
    },

    getBackground() {
      return `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${this.url})`;
    }
  }
};
</script>

<template>
  <div class="news-container" :style="{ 'background': getBackground() }">
    <span class="info">
      <h2>{{ title }}</h2>
      <span>{{ published }}</span>
    </span>
  </div>
</template>

<style lang="scss" scoped>
  .news-container {
    width: 600px;
    height: 350px;
    border-radius: 10px;
    display: inline-block;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    position: relative;

    .info {
      left: 20px;
      bottom: 20px;
      color: #fff;
      text-align: left;
      position: absolute;
    }
  }
</style>
